import React from "react";
import { ShieldCheck } from "tabler-icons-react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Wrapper from "../components/wrapper";

import { Flex, Text, useTheme } from "../styled";

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Datenschutzbestimmungen"
        keywords={[`gatsby`, `application`, `react`]}
      />
      <Wrapper maxWidth={800} py="5rem">
        <Flex>
          <Text font="h3" color="secondary">
            Datenschutzbestimmungen
          </Text>
          <Text font="h5" py={15} color="secondary">
            Allgemeiner Hinweis und Pflichtinformationen - Benennung der
            verantwortlichen Stelle
          </Text>
          <Text>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
            <br />
            <br />
            WKM Rösner
            <br />
            Friederike Rösner
            <br />
            Im Weiherfeld 12
            <br />
            41334 Nettetal
            <br />
            <br />
            Die verantwortliche Stelle entscheidet allein oder gemeinsam mit
            anderen über die Zwecke und Mittel der Verarbeitung von
            personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
          </Text>

          <Text font="h5" py={15} color="secondary">
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </Text>
          <Text>
            Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
            Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
            Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
            formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
            Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </Text>

          <Text font="h5" py={15} color="secondary">
            Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
          </Text>
          <Text>
            Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
            Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
            zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher
            Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem
            sich der Sitz unseres Unternehmens befindet. Der folgende Link
            stellt eine Liste der Datenschutzbeauftragten sowie deren
            Kontaktdaten bereit:{" "}
            <a
              href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
              target="_blank"
            >
              BFDI Bund
            </a>
            .
          </Text>

          <Text font="h5" py={15} color="secondary">
            Recht auf Datenübertragbarkeit
          </Text>
          <Text>
            Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
            Einwilligung oder in Erfüllung eines Vertrags automatisiert
            verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
            Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie
            die direkte Übertragung der Daten an einen anderen Verantwortlichen
            verlangen, erfolgt dies nur, soweit es technisch machbar ist.
          </Text>

          <Text font="h5" py={15} color="secondary">
            Recht auf Auskunft, Berichtigung, Sperrung, Löschung
          </Text>
          <Text>
            Sie haben jederzeit im Rahmen der geltenden gesetzlichen
            Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, Herkunft der Daten, deren
            Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und
            auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie
            sich jederzeit über die im Impressum aufgeführten
            Kontaktmöglichkeiten an uns wenden.
          </Text>

          <Text font="h5" py={15} color="secondary">
            SSL- bzw. TLS-Verschlüsselung
          </Text>
          <Text>
            Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
            Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere
            Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie
            über diese Website übermitteln, für Dritte nicht mitlesbar. Sie
            erkennen eine verschlüsselte Verbindung an der „https://“
            Adresszeile Ihres Browsers und am Schloss-Symbol in der
            Browserzeile.
          </Text>

          <Text font="h5" py={15} color="secondary">
            Kontaktformular
          </Text>
          <Text>
            Per Kontaktformular übermittelte Daten werden einschließlich Ihrer
            Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder
            um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten
            findet ohne Ihre Einwilligung nicht statt.
            <br />
            <br />
            Die Verarbeitung der in das Kontaktformular eingegebenen Daten
            erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs.
            1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung
            ist jederzeit möglich. Für den Widerruf genügt eine formlose
            Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf
            erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
            <br />
            <br />
            Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis
            Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
            widerrufen oder keine Notwendigkeit der Datenspeicherung mehr
            besteht. Zwingende gesetzliche Bestimmungen - insbesondere
            Aufbewahrungsfristen - bleiben unberührt.
          </Text>

          <Text font="h5" py={15} color="secondary">
            Cookies
          </Text>
          <Text>
            Unsere Website verwendet Cookies. Das sind kleine Textdateien, die
            Ihr Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns
            dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu
            machen. Einige Cookies sind “Session-Cookies.” Solche Cookies werden
            nach Ende Ihrer Browser-Sitzung von selbst gelöscht. Hingegen
            bleiben andere Cookies auf Ihrem Endgerät bestehen, bis Sie diese
            selbst löschen. Solche Cookies helfen uns, Sie bei Rückkehr auf
            unserer Website wiederzuerkennen. Mit einem modernen Webbrowser
            können Sie das Setzen von Cookies überwachen, einschränken oder
            unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass
            Cookies mit dem Schließen des Programms von selbst gelöscht werden.
            Die Deaktivierung von Cookies kann eine eingeschränkte
            Funktionalität unserer Website zur Folge haben. Das Setzen von
            Cookies, die zur Ausübung elektronischer Kommunikationsvorgänge oder
            der Bereitstellung bestimmter, von Ihnen erwünschter Funktionen
            (z.B. Warenkorb) notwendig sind, erfolgt auf Grundlage von Art. 6
            Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein
            berechtigtes Interesse an der Speicherung von Cookies zur technisch
            fehlerfreien und reibungslosen Bereitstellung unserer Dienste.
            Sofern die Setzung anderer Cookies (z.B. für Analyse-Funktionen)
            erfolgt, werden diese in dieser Datenschutzerklärung separat
            behandelt.
          </Text>

          <Text font="h5" py={15} color="secondary">
            Google Web Fonts
          </Text>
          <Text>
            Unsere Website verwendet Web Fonts von Google. Anbieter ist die
            Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043,
            USA. Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die
            von uns gewünschte Darstellung unserer Website zu präsentieren,
            unabhängig davon welche Schriften Ihnen lokal zur Verfügung stehen.
            Dies erfolgt über den Abruf der Google Web Fonts von einem Server
            von Google in den USA und der damit verbundenen Weitergabe Ihre
            Daten an Google. Dabei handelt es sich um Ihre IP-Adresse und welche
            Seite Sie bei uns besucht haben. Der Einsatz von Google Web Fonts
            erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. <br />
            <br />
            Als Betreiber dieser Website haben wir ein berechtigtes Interesse an
            der optimalen Darstellung und Übertragung unseres Webauftritts. Das
            Unternehmen Google ist für das us-europäische
            Datenschutzübereinkommen "Privacy Shield" zertifiziert. Dieses
            Datenschutzübereinkommen soll die Einhaltung des in der EU geltenden
            Datenschutzniveaus gewährleisten. <br />
            <br />
            Einzelheiten über Google Web Fonts finden Sie unter:
            <br />
            <a
              href="https://www.google.com/fonts#AboutPlace:about"
              target="_blank"
            >
              https://www.google.com/fonts#AboutPlace:about
            </a>{" "}
            und weitere Informationen in den Datenschutzbestimmungen von Google:
            <a
              href="https://policies.google.com/privacy/partners?hl=de"
              target="_blank"
            >
              https://policies.google.com/privacy/partners?hl=de
            </a>
            <br />
            <br />
            Quelle: Datenschutz-Konfigurator von{" "}
            <a href="www.mein-datenschutzbeauftragter.de" target="_blank">
              mein-datenschutzbeauftragter.de
            </a>
          </Text>
        </Flex>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
